import React from 'react';
import '@wordpress/block-library/build-style/editor.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';
import Footer from './Footer';
import Header from './Header';
export const Layout = ({ children }) => {

  return (

    <>
    <Header />
    <main className="text-gray-900">{children}</main>
    <Footer />
  </>  
  );
};

export default Layout;