import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Button from '../Button';
import Logo from '../../svg/dan-bagnall-logo.svg';


const Header = () => (
  <header className="sticky top-0 bg-white shadow z-10">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
      <div className="w-12 mr-3">
      <AnchorLink stripHash to="/#home" > 
      
      <Logo className="" />
      
      </AnchorLink>
        </div>
        <AnchorLink stripHash to="/#home" > <p>Dan Bagnall</p>  </AnchorLink>
      </div>
      <div className="flex mt-4 sm:mt-0 uppercase font-sans">
        <AnchorLink className="px-4" stripHash to="/#work">
          Work
        </AnchorLink>
        <AnchorLink className="px-4" stripHash to="/#skills">
          Skills
        </AnchorLink>
        <AnchorLink className="px-4" stripHash to="/#clients">
          Clients
        </AnchorLink>
        <AnchorLink className="px-4" stripHash  to="/#blog">
          Blog
        </AnchorLink>
      </div>
      <div className="hidden md:block">
      <AnchorLink stripHash  to="/#contact"><Button className="text-sm">Contact Me</Button></AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
