import React from 'react';
import { OutboundLink } from "gatsby-plugin-google-gtag"


const Footer = () => (
  <footer className="container mx-auto lg:py-16 px-3  lg:mt-20 mb-8 text-gray-800">
    <hr className="pb-10 lg:pt-10"></hr>
    <div className="grid grid-cols-1 lg:flex mx-3">
      <div className="pt-10 flex-1 px-3">
        <h2 className="text-lg font-semibold">About Me</h2>
        <p className="mt-4 pr-20 font-light">
          Senior leader for growth stage and fortune 500 media, data, and technology businesses.
          <br></br><br></br>
          Currently residing in Brooklyn, NY. In my spare time I enjoy home brewing beer and cider, playing guitar, 35mm photography, and cooking sous vide. 
        </p>

      </div>
      <div className="pt-10 flex-1 px-3">
        <h2 className="text-lg font-semibold">Made Possible Thanks To</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <OutboundLink href="https://internetcomputer.org/">Internet Computer</OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://dfinity.org/">DFINITY Foundation </OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://fleek.co/">Fleek</OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://www.gatsbyjs.com/">Gatbsy</OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://www.wpgraphql.com/">WPGraphQL</OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://github.com/codebushi/gatsby-starter-lander">Codebushi</OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://undraw.co/">Undraw</OutboundLink>
          </li>
        </ul>
      </div>
      <div className="pt-10 flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <OutboundLink href="https://www.linkedin.com/in/danbagnall">LinkedIn</OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://twitter.com/dan_bagnall">Twitter</OutboundLink>
          </li>
          <li>
            <OutboundLink href="https://github.com/dbagnall">GitHub</OutboundLink>
          </li>
        </ul>
      </div>
    </div>
    <p className="pt-20 font-light text-center">Copyright Ⓒ Dan Bagnall 2022</p>
  </footer>
);

export default Footer;
